import React from 'react'
const NotFound = () => {
  return (
    <>
    <p className='text-danger'>ERROR 404 Not Found</p>
    <p>Sorry, We don't have that page</p>
    <p><a href="https://grpenterprise.com/">Go back</a></p>
    </>
  )
}

export default NotFound